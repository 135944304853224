const axios = require('axios').default;
import VueW3CValid from 'vue-w3c-valid';

new VueW3CValid({
    el: '#autocomplete'
});

var whisper = new Vue({
    el: '#autocomplete',
    delimiters: ['${', '}'],
    data: {
        response: false,
        searchQuery: "",
        toggle: false,
        w8ForResponse: true,
        response1: false,
        active: false,
        timeoutData: null,
        cancelTokenSource: null,
        autocompleteApi: window.hpData.autocompleteApi,
        data: [],
        collabsibles: [
            {
                id: "AppWhisper__body",
            },

        ]
    },
    methods: {

        search() {
            if (this.searchQuery.length > 0 || !this.w8ForResponse) {
                this.w8ForResponse = false;
                this.getData();
            } else {
                this.response1 = false
            }
        },

        getData() {
            var self = this;

            if (this.timeoutData) {
                window.clearTimeout(this.timeoutData);
                this.timeoutData = null;
            }

            if (this.cancelTokenSource) {
                this.cancelTokenSource.cancel();
                this.cancelTokenSource = null;
            }

            this.cancelTokenSource = axios.CancelToken.source();
            this.timeoutData = window.setTimeout(function () {
                self.timeoutData = null;
                axios.get(self.autocompleteApi, {
                    cancelToken: self.cancelTokenSource.token,
                    params: {
                        search: self.searchQuery
                    }
                })
                    .then(function (response) {
                        self.data = response.data;
                        if (response) {
                            self.w8ForResponse = true;
                            self.response1 = true;
                            self.toggle = true;
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }, 300);
        },

        goActive() {
            this.active = true;
        },

        onClickOutside(event) {
            const input = document.querySelector('#search');
            if (event.target.classList === input.classList) {
                if (this.searchQuery.length > 0) {
                    this.active = true
                    this.toggle = true
                }
            } else {
                this.active = false
                this.toggle = false
            }

        }
    },

    mounted() {
        document.addEventListener("click", this.onClickOutside);
    },
    beforeDestroy() {
        document.removeEventListener("click", this.onClickOutside);
    }
});
